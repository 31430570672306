<!--
 * @Author: ChenYaJin
 * @Date: 2023-11-06 17:46:01
 * @LastEditors: ChenYaJin
 * @LastEditTime: 2023-11-08 10:24:27
 * @Description: logo切换
-->
<template>
  <div class="layout-logo">
    <img
      v-show="!config.layout.menuCollapse"
      class="h-35px"
      src="/images/admin/logo-max.png"
      alt="logo"
    />
    <img
      v-show="config.layout.menuCollapse"
      alt="logo"
      class="h-35px"
      src="/images/admin/logo-min.png"
    />
  </div>
</template>

<script setup lang="ts">
  import { useConfig } from '~/stores/config'
  const config = useConfig()
</script>

<style scoped lang="scss">
  .layout-logo {
    @apply w-full h-64px flex-center box-border;
    background: linear-gradient(to right, #53c461, #299132);
  }
</style>
